import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { HttpParams } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';
import {
  AuthService,
  CaseService,
  FieldSpec,
  FilterSpec,
  IUiMeta,
  L10nService,
} from '@zipcrim/common';
import { Case } from '@zipcrim/common/models';
import { OptionConfig } from '@zipcrim/forms';
import { differenceInDays, isToday, parseISO } from 'date-fns';
import { forkJoin } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  skip,
  switchMap,
  tap,
} from 'rxjs/operators';

/**
 * Dashboard component.
 */
@Component({
  templateUrl: './dashboard.component.html',
  animations: [
    trigger('slideFadeAnimation', [
      state(
        'in',
        style({
          opacity: 1,
          height: '*',
        })
      ),
      transition('* => void', [
        style({
          height: '!',
          opacity: 1,
        }),
        animate(
          500,
          style({
            height: 0,
            opacity: 0,
          })
        ),
      ]),
      transition('void => *', [
        style({
          height: 0,
          opacity: 0,
          background: '#f5edc7',
        }),
        animate(800),
      ]),
    ]),
  ],
})
export class DashboardV2Component implements OnInit, AfterViewInit {
  constructor(
    private _ActivatedRoute: ActivatedRoute,
    private _AuthService: AuthService,
    private _Case: CaseService,
    private _L10n: L10nService
  ) {}

  /**
   * Form reference.
   */
  @ViewChild('Form', { static: true })
  Form: NgForm;

  /**
   * Filter options.
   */
  Filter: { named: string; search: string; sort: string } = {
    named: null,
    search: null,
    sort: null,
  };

  /**
   * Sort options.
   */
  readonly SortOptions: OptionConfig[] = [
    {
      labelL10nKey: 'Common.lblSortByNewFirst',
      value: 'Completed',
    },
    {
      labelL10nKey: 'Common.lblSortByLastName',
      value: '#LastName',
    },
  ];

  /**
   * Busy loading indicator.
   */
  BusyLoading: boolean;

  /**
   * Busy loading more indicator.
   */
  BusyMore: boolean;

  /**
   * Pinned cases.
   */
  Pinned: Case[] = [];

  /**
   * Today's cases.
   */
  Today: Case[] = [];

  /**
   * This week's cases.
   */
  ThisWeek: Case[] = [];

  /**
   * All other (non-hidden) cases.
   */
  Other: Case[] = [];

  /**
   * All available cases.
   */
  AllCases: Case[] = [];

  /**
   * Indicates if user has rights to add cases.
   */
  CanAddCase = false;

  /**
   * Indicates if any cases are present.
   */
  HasAny = true;

  /**
   * Indicates if case groups should be shown, instead of all.
   */
  ShowGroups = true;

  /**
   * Indicates if more cases are available from API.
   */
  get HasMore() {
    return this._LastUiMeta?.CountRemaining > 0;
  }

  /**
   * UI meta from last API response.
   */
  private _LastUiMeta: IUiMeta;

  /**
   * Filter spec skip.
   */
  private _Skip = 0;

  /**
   * Filter spec take.
   */
  private _Take = 50;

  /**
   * On init.
   */
  ngOnInit() {
    const params = this._ActivatedRoute.snapshot.queryParamMap;
    this._SetParams(params);

    this.BusyLoading = true;
    forkJoin([this._GetAnyCase(), this._GetCases(), this._GetRights()])
      .pipe(finalize(() => (this.BusyLoading = false)))
      .subscribe();

    this._ActivatedRoute.queryParamMap
      .pipe(
        skip(1),
        tap((res) => this._SetParams(res)),
        switchMap(() => this._GetCases())
      )
      .subscribe();
  }

  /**
   * After view init.
   */
  ngAfterViewInit() {
    this.Form.valueChanges
      .pipe(
        skip(2),
        distinctUntilChanged(),
        debounceTime(400),
        tap(() => (this._Skip = 0)),
        switchMap(() => this._GetCases())
      )
      .subscribe();
  }

  /**
   * On case pinned/hidden change.
   */
  OnCaseChange() {
    this._SetCaseGroups();
  }

  /**
   * On load more.
   */
  OnLoadMore() {
    this._Skip += 50;
    this.BusyMore = true;
    this._GetCases()
      .pipe(finalize(() => (this.BusyMore = false)))
      .subscribe();
  }

  /**
   * Set params from router params map.
   *
   * @param params Router param map.
   */
  private _SetParams(params: ParamMap) {
    const searchAll = params.has('searchAll');

    // default filter is `Active`, but clear filter when `searchAll` is present
    this.Filter.named = searchAll ? null : params.get('filter') || 'Active';
    this.ShowGroups = !searchAll;
    this._Skip = 0;
  }

  /**
   * Check for any cases, without filter.
   */
  private _GetAnyCase() {
    const fieldSpec = new FieldSpec().add(['CaseNum']);
    const params = new HttpParams().append('fieldSpec', fieldSpec.toString());

    return this._Case
      .list(params)
      .pipe(tap((res) => (this.HasAny = res.Items.length !== 0)));
  }

  /**
   * Get case rights.
   */
  private _GetRights() {
    return this._AuthService
      .hasRight('Case', 'Add')
      .pipe(tap((res) => (this.CanAddCase = res)));
  }

  /**
   * Get cases.
   */
  private _GetCases() {
    const fieldSpec = new FieldSpec().named('Summary');
    const filterSpec = new FilterSpec().paged(this._Skip, this._Take);

    if (this.Filter.named) {
      filterSpec.named(this.Filter.named);
    }

    if (this.Filter.sort) {
      filterSpec.sort([this.Filter.sort]);
    }

    if (this.Filter.search) {
      filterSpec.match(this.Filter.search);
    }

    if (this._LastUiMeta) {
      filterSpec.queryId(+this._LastUiMeta.QueryCode);
    }

    const params = new HttpParams()
      .append('fieldSpec', fieldSpec.toString())
      .append('filterSpec', filterSpec.toString());

    return this._Case.list(params).pipe(
      tap((res) => (this._LastUiMeta = res.UiMeta)),
      map((res) => res.Items.map((x) => new Case(x, this._L10n))),
      tap((res) => {
        if (this._Skip > 0) {
          // loading **more** result, append to existing
          this.AllCases.push(...res);
        } else {
          this.AllCases = res;
        }

        this._SetCaseGroups();
      })
    );
  }

  /**
   * Split available cases into groups for display.
   */
  private _SetCaseGroups() {
    const pinned: Case[] = [];
    const today: Case[] = [];
    const thisWeek: Case[] = [];
    const other: Case[] = [];

    this.AllCases.forEach((item) => {
      if (item.IsHidden) {
        return;
      }

      if (item.IsPinned) {
        pinned.push(item);
        return;
      }

      const date = parseISO(item.Submitted);
      const now = new Date();

      if (isToday(date)) {
        today.push(item);
      } else if (differenceInDays(now, date) <= 7) {
        thisWeek.push(item);
      } else {
        other.push(item);
      }
    });

    this.Pinned = pinned;
    this.Today = today;
    this.ThisWeek = thisWeek;
    this.Other = other;
  }
}
